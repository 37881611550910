<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-container class="mb-16">
    <v-card elevation="2">
      <div class="pa-3">
        <div class="survey-title">
          <b>{{ $t('custom.hosted_buyers.title') }}</b>
        </div>
        <div class="survey-subtitle">
          {{ $t('custom.hosted_buyers.subtitle') }}
        </div>
      </div>
      <v-divider />
      <div>
        <slide-fade-transition>
          <v-container
            v-if="!message"
            fluid
          >
            <v-row>
              <v-col
                class="text-center"
                cols="12"
              >
                <v-img
                  class="mx-auto"
                  src="/simic_hb-logo2025.png"
                  width="300px"
                />
              </v-col>
              <v-col cols="12">
                <div
                  class="survey-description"
                  v-html="$t('custom.hosted_buyers.description')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="survey-title">
                  <b>{{ $t('custom.hosted_buyers.title_1') }}</b>
                </div>
              </v-col>
              <app-text-input
                v-model="form.company_name"
                cols="12"
                name="company_name"
                required
                sm="6"
              />
              <app-text-input
                v-model="form.name"
                cols="12"
                name="name"
                required
                sm="6"
              />
              <app-text-input
                v-model="form.job_title"
                cols="12"
                name="job_title"
                required
                sm="6"
              />
              <app-text-input
                v-model="form.sector"
                cols="12"
                name="sector"
                required
                sm="6"
              />
            </v-row>
            <v-row v-show="showRating">
              <v-col cols="12">
                <div class="survey-title">
                  <b>{{ $t('custom.hosted_buyers.title_2') }}</b>
                </div>
              </v-col>
              <template v-for="(inx) in 5">
                <v-col
                  v-if="inx === 1"
                  :key="`f1-${inx}`"
                  cols="12"
                >
                  <div class="survey-subtitle">
                    <b>{{ $t('custom.hosted_buyers.evaluation_1') }}</b>
                  </div>
                </v-col>
                <v-col
                  v-if="inx === 5 && !!form[`survey4`]"
                  :key="`faa-${inx}`"
                  cols="12"
                >
                  <div class="survey-subtitle">
                    <b>{{ $t('custom.hosted_buyers.evaluation_2') }}</b>
                  </div>
                </v-col>
                <v-col
                  v-show="form[`survey${inx-1}`] || inx === 1"
                  :key="`ff${inx}`"
                  cols="12"
                >
                  <div class="survey-q">
                    <span><b>{{ parseAttribute(`survey${ inx }`) }}</b></span>
                  </div>
                  <v-rating
                    v-model="form[`survey${inx}`]"
                    background-color="blue"
                    color="blue"
                    hover
                    length="5"
                    size="40"
                  />
                </v-col>
              </template>
            </v-row>
            <v-row v-show="!!form.survey5">
              <v-col cols="12">
                <div class="survey-title">
                  <b>{{ $t('custom.hosted_buyers.evaluation_3') }}</b>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="survey-subtitle">
                  {{ parseAttribute('survey6') }}
                </div>
              </v-col>
              <app-textarea-input
                v-model="form.survey6"
                :label="$t('custom.hosted_buyers.evaluation_typing')"
                clearable
                cols="12"
                name="survey6"
              />
            </v-row>
          </v-container>
        </slide-fade-transition>
        <slide-fade-transition>
          <v-container
            v-if="message"
            fluid
          >
            <v-row>
              <v-col
                class="text-center"
                cols="12"
              >
                <v-img
                  class="mx-auto"
                  src="/simic_hb-logo2025.png"
                  width="300px"
                />
              </v-col>
              <v-col
                class="text-center"
                cols="12"
              >
                <div class="">
                  {{ message }}
                </div>
                <app-btn
                  class="mt-5"
                  color="blue"
                  @click="startAgain()"
                >
                  <div class="white--text text-h5">
                    {{ $t('custom.survey_start_again') }}
                  </div>
                </app-btn>
              </v-col>
            </v-row>
          </v-container>
        </slide-fade-transition>
      </div>
      <v-divider />
      <v-card-actions id="actions">
        <app-btn
          v-show="(form.survey5 !== null && !message)"
          :disabled="disabled"
          :loading="loading"
          class="white--text"
          color="blue"
          @click="submit"
        >
          <v-icon left>
            send
          </v-icon>
          {{ $t('send') }}
        </app-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>

import { MetaInfoMixin } from '@mixins'
import { isDevelopment } from '@helpers/Tools'
import SlideFadeTransition from '@components/SlideFadeTransition.vue'

export default {
  name: 'NewSurvey',
  components: { SlideFadeTransition },
  mixins: [MetaInfoMixin],
  data () {
    return {
      loading: !1,
      message: null,
      form: {
        company_name: null,
        name: null,
        job_title: null,
        sector: null,
        survey1: null,
        survey2: null,
        survey3: null,
        survey4: null,
        survey5: null,
        survey6: null
      }
    }
  },
  computed: {
    disabled () {
      return !1
    },
    showRating () {
      return !!this.form.company_name && !!this.form.name && !!this.form.job_title && !!this.form.sector
    }
  },
  watch: {
    // form: {
    //  handler (v) {
    //    this.gotoActions()
    //  },
    //  deep: true
    // }
    'form.sector': {
      handler (v) {
        this.gotoActions()
      },
      deep: true
    },
    'form.survey1': {
      handler (v) {
        this.gotoActions()
      },
      deep: true
    },
    'form.survey2': {
      handler (v) {
        this.gotoActions()
      },
      deep: true
    },
    'form.survey3': {
      handler (v) {
        this.gotoActions()
      },
      deep: true
    },
    'form.survey4': {
      handler (v) {
        this.gotoActions()
      },
      deep: true
    },
    'form.survey5': {
      handler (v) {
        this.gotoActions()
      },
      deep: true
    }
  },
  mounted () {
    const a = document.querySelector('html')
    if (a) {
      a.classList.add('hbl')
    }
  },
  methods: {
    isDevelopment () {
      return isDevelopment
    },
    gotoActions () {
      this.$vuetify.goTo(document.getElementById('actions'), {
        duration: 1000
      })
    },
    startAgain () {
      this.message = null
      this.form = {
        company_name: null,
        name: null,
        job_title: null,
        sector: null,
        survey1: null,
        survey2: null,
        survey3: null,
        survey4: null,
        survey5: null,
        survey6: null
      }
    },
    async submit () {
      if (this.loading) return
      this.loading = !0
      this.message = null
      try {
        const { _message } = await this.apiService.survey.inquiry({ ...this.form, hbs: 1 })
        this.message = _message || null
      } catch (e) {
        const { _message } = e || {}
        _message && this.alertError(_message)
      } finally {
        this.loading = !1
      }
    }
  }
}
</script>

<style lang="scss">
.survey-description {
  font-size: 16px;
  text-align: justify;
}

.survey-title {
  font-size: 18px;
}

.survey-subtitle {
  font-size: 16px;
}

.survey-q {
  font-size: 14px;
}
</style>
